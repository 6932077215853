import {isSearchMalformed, fixMalformedSearch} from './';

export default function (search = '') {
  const searchString = isSearchMalformed(search)
    ? fixMalformedSearch(search)
    : search;
  const queryParams = new URLSearchParams(searchString);

  const utmParams = {};
  for (const [key, value] of queryParams) {
    if (key.startsWith('utm_')) {
      utmParams[key.split('utm_')[1]] = value;
    }
  }
  return utmParams;
}
